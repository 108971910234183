import { forwardRef, HTMLProps } from 'react'

import { InputWrapper, InputWrapperProps } from '../input-wrapper'

import s from './textarea.module.scss'

export interface TextareaProps
  extends Omit<HTMLProps<HTMLTextAreaElement>, 'onClick'>,
    InputWrapperProps {
  onClick?: InputWrapperProps['onClick']
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      isError,
      pre: _pre,
      post: _post,
      disabled,
      placeholder,
      onClick,
      variant,
      ...rest
    } = props

    return (
      <InputWrapper
        isError={isError}
        disabled={disabled}
        onClick={onClick}
        className={s.root}
        placeholder={placeholder}
        variant={variant}
      >
        <textarea
          ref={ref}
          disabled={disabled}
          id={rest.name}
          placeholder={placeholder}
          {...rest}
        />
      </InputWrapper>
    )
  },
)

Textarea.displayName = 'Textarea'
