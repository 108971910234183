import { forwardRef } from 'react'

import { InputWrapper, InputWrapperProps } from '../input-wrapper'

export interface InputProps
  extends React.HTMLProps<HTMLInputElement>,
    Omit<InputWrapperProps, 'name' | 'onClick' | 'placeholder'> {
  placeholder?: InputWrapperProps['placeholder']
  name?: InputWrapperProps['name']
  onClick?: InputWrapperProps['onClick']
  wrapperClassName?: InputWrapperProps['className']
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    isError,
    autoComplete,
    type = 'text',
    placeholder,
    pre,
    post,
    disabled,
    onClick,
    s,
    wrapperClassName,
    isSelect,
    variant,
    ...rest
  } = props

  return (
    <InputWrapper
      pre={pre}
      post={post}
      s={s}
      isError={isError}
      disabled={disabled}
      placeholder={placeholder}
      name={rest.name}
      onClick={onClick}
      isSelect={isSelect}
      className={wrapperClassName}
      variant={variant}
    >
      <input
        ref={ref}
        type={type}
        autoComplete={autoComplete}
        disabled={disabled}
        id={rest.name}
        placeholder={placeholder}
        {...rest}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value.replace(/\s/g, '').length === 0) {
            e.target.value = ''
          }
        }}
      />
    </InputWrapper>
  )
})

Input.displayName = 'Input'
