import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { DashboardFilterRequest, getEstimatedPriceDetails } from '../api'
import { DASHBOARD_ESTIMATED_PRICE_DETAILS_QUERY_KEY } from '../constants'

export const useEstimatedPriceDetails = (filter: DashboardFilterRequest) => {
  const { data, ...rest } = useQuery({
    queryKey: [DASHBOARD_ESTIMATED_PRICE_DETAILS_QUERY_KEY, filter],
    queryFn: () => getEstimatedPriceDetails(filter),
    placeholderData: keepPreviousData,
  })

  return { estimatedPriceDetails: data, ...rest }
}
