import { RentAreaListItem } from '../rent-area/types'

import { DashboardFilterRequest } from './api'
import { DashboardFilterFormValues, DashboardFilterSearch } from './types'

export const parseDashboardFormValuesToFilterSearch = (
  filter: DashboardFilterFormValues,
): DashboardFilterSearch => {
  return {
    projects: filter.projects?.map(({ id }) => id) || [],
    rentAreas: filter.rentAreas?.map(({ rentAreaId }) => rentAreaId) || [],
    dateMin: filter.dateMin || '',
    dateMax: filter.dateMax || '',
  }
}

export const parseDashboardFilterSearchToFormValues = ({
  filter,
  projects,
  rentAreas,
}: {
  filter: DashboardFilterSearch
  projects: RentAreaListItem['project'][]
  rentAreas: RentAreaListItem[]
}): DashboardFilterFormValues => {
  return {
    projects: projects.filter((p) => filter.projects?.includes(p.id)),
    rentAreas: rentAreas.filter((r) =>
      filter.rentAreas?.includes(r.rentAreaId),
    ),
    dateMin: filter.dateMin,
    dateMax: filter.dateMax,
  }
}

export const parseDashboardFormValuesToFilterRequest = (
  f: DashboardFilterFormValues,
): DashboardFilterRequest => {
  return {
    periodFilter: {
      start: f.dateMin || undefined,
      end: f.dateMax || undefined,
    },
    projectIds: f.projects?.map(({ id }) => id),
    rentAreaIds: f.rentAreas?.map(({ rentAreaId }) => rentAreaId),
  }
}
