import { forwardRef, HTMLProps, ReactElement, ReactNode, Ref } from 'react'

import { Button } from '../button'
import { Flex } from '../flex'
import { Icon } from '../icon'
import { IconSimpleButton } from '../icon-simple-button'
import { Input } from '../input'
import { InputWrapperProps } from '../input-wrapper'
import { CircleLoader } from '../svg'
import { Text } from '../text'

import { Option } from './option'
import { OptionListWrapper } from './option-list-wrapper'
import { DefaultOption, RenderOptionProps } from './types'
import { useSelect } from './use-select'

import styles from './select.module.scss'

interface SelectRestProps<T>
  extends Omit<
    HTMLProps<HTMLInputElement>,
    'onChange' | 'value' | 'onClick' | 'type'
  > {
  isError?: InputWrapperProps['isError']
  variant?: InputWrapperProps['variant']
  s?: InputWrapperProps['s']
  labelName?: keyof T
  valueName?: keyof T
  renderOption?: (props: RenderOptionProps<T>) => ReactNode
  isLoading?: boolean
  isClearable?: boolean
  footer?: (onClose: () => void) => ReactNode
  displayValue?: string
}

export type SelectProps<T> =
  | (SelectRestProps<T> & {
      type: 'single'
      value?: T | null
      options?: T[]
      onChange?: (option: T | null, isCreated: boolean) => void
      isSearchable?: boolean
      loadOptions?: (query: string) => Promise<T[]>
      isCreatable?: boolean
      notFoundText?: string
      minCharForSearch?: number
    })
  | (SelectRestProps<T> & {
      type: 'multi'
      value?: T[]
      options?: T[]
      onChange?: (options: T[]) => void
      isSearchable?: never
      loadOptions?: never
      isCreatable?: never
      notFoundText?: never
      minCharForSearch?: never
    })

const SelectComponent = <T extends DefaultOption>(
  props: SelectProps<T>,
  ref: Ref<HTMLInputElement>,
) => {
  const {
    options: propsOptions,
    disabled,
    placeholder,
    s,
    value,
    onChange,
    isError,
    labelName = 'label',
    valueName = 'value',
    onBlur,
    type = 'single',
    renderOption,
    isSearchable = false,
    isClearable = false,
    loadOptions,
    notFoundText,
    isCreatable = false,
    minCharForSearch = 3,
    isLoading = false,
    footer,
    readOnly,
    displayValue,
    variant,
  } = props

  const {
    flags: { isOptionsOpened, isLoadingOptions },
    data: { inputValue, valueSet, options },
    actions: {
      handleClickInput,
      handleInputBlur,
      handleClickOption,
      handleClickClear,
      handleInputChange,
      handleCreateOption,
      onClose,
    },
  } = useSelect(
    // @ts-ignore
    {
      disabled,
      onChange,
      value,
      labelName,
      valueName,
      onBlur,
      type,
      isSearchable,
      propsOptions,
      loadOptions,
      minCharForSearch,
      footer,
      readOnly,
    },
  )

  return (
    <div className={styles.root}>
      <Input
        post={
          isLoadingOptions || isLoading ? (
            <CircleLoader className={styles.loader} />
          ) : isClearable && inputValue && !disabled ? (
            <IconSimpleButton s="20" icon="close" onClick={handleClickClear} />
          ) : !isSearchable ? (
            <Icon
              s="20"
              name={isOptionsOpened ? 'arrow-up-s' : 'arrow-down-s'}
            />
          ) : null
        }
        s={s}
        className={styles.input}
        readOnly={!isSearchable}
        ref={ref}
        disabled={disabled}
        isError={isError}
        value={displayValue || inputValue}
        onChange={handleInputChange}
        onClick={handleClickInput}
        onBlur={handleInputBlur}
        autoComplete="off"
        autoCapitalize="none"
        spellCheck="false"
        isSelect={!isSearchable}
        placeholder={placeholder}
        variant={variant}
      />
      {isOptionsOpened && (
        <OptionListWrapper footer={footer?.(onClose)}>
          <div>
            {options?.map((option, i) => (
              <Option<T>
                key={i}
                // index={i}
                option={option}
                isSelected={valueSet.has(option[valueName])}
                labelName={labelName}
                onClickOption={handleClickOption}
                renderOption={renderOption}
              />
            ))}

            {loadOptions && options?.length === 0 && notFoundText && (
              <>
                <Flex p="12">
                  <Text s="body" color="text-300">
                    {notFoundText}
                  </Text>
                </Flex>
              </>
            )}

            {isCreatable && loadOptions && options?.length === 0 && (
              <>
                <Flex
                  jc="space-between"
                  ai="center"
                  mt="8"
                  mr="8"
                  rg="8"
                  p="12"
                  rad="6"
                  bgColor="base-100"
                  w="wrap"
                >
                  <Text s="body">{inputValue}</Text>
                  <Button
                    variant="primary"
                    s="s"
                    pre={<Icon name="check" />}
                    onClick={handleCreateOption}
                    disabled={isLoadingOptions}
                  >
                    Добавить
                  </Button>
                </Flex>
              </>
            )}
          </div>
        </OptionListWrapper>
      )}
    </div>
  )
}

export const Select = forwardRef(SelectComponent) as <T>(
  props: SelectProps<T> & { ref?: Ref<HTMLInputElement> },
) => ReactElement
