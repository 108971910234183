import { FilesUpload } from '../../../../shared/components/files-upload'
import {
  IMAGE_FILE_EXTENSIONS,
  IMAGE_MIME_TYPES,
} from '../../../../shared/constants/files'
import { FileDetails } from '../../../../shared/types/api-types'
import { Input } from '../../../../shared/uikit/input'
import { InputDate } from '../../../../shared/uikit/input-date'
import { InputPattern } from '../../../../shared/uikit/input-pattern'
import { InputPhone } from '../../../../shared/uikit/input-phone'
import { Select } from '../../../../shared/uikit/select'
import { Text } from '../../../../shared/uikit/text'
import { Textarea } from '../../../../shared/uikit/textarea'
import { OrderFilePreview } from '../../../order/components/order-file-preview'
import { ORDER_FIELD_TYPES } from '../../constants-schema'
import { OrderSettingsField } from '../../types-schema'

interface CustomFieldProps {
  orderId?: string
  field: OrderSettingsField
  isReadOnly: boolean
  value?: string[]
  files?: File[]
  remoteFiles?: FileDetails[]
  onChangeValue?: (value: string[]) => void
  onChangeFiles?: (value: File[]) => void
}

export const CustomField = ({
  orderId,
  field,
  isReadOnly,
  value,
  files = [],
  remoteFiles = [],
  onChangeValue,
  onChangeFiles,
}: CustomFieldProps) => {
  if (field.type === ORDER_FIELD_TYPES.text) {
    return (
      <Input
        placeholder={field.placeholder}
        readOnly={isReadOnly}
        value={value || ''}
        onChange={(e) => onChangeValue?.([e.currentTarget.value])}
      />
    )
  } else if (field.type === ORDER_FIELD_TYPES.multilineText) {
    return (
      <Textarea
        placeholder={field.placeholder}
        rows={4}
        readOnly={isReadOnly}
        value={value || ''}
        onChange={(e) => onChangeValue?.([e.currentTarget.value])}
      />
    )
  } else if (field.type === ORDER_FIELD_TYPES.singleSelect) {
    const selectedValue = field.options.find((o) => o.value === value?.[0])

    return (
      <Select
        type="single"
        placeholder={field.placeholder}
        options={field.options}
        readOnly={isReadOnly}
        value={selectedValue}
        onChange={(option) =>
          onChangeValue?.(option?.value ? [option?.value] : [])
        }
      />
    )
  } else if (field.type === ORDER_FIELD_TYPES.multiSelect) {
    const selectedValues = field.options.filter(
      (o) => Array.isArray(value) && value.includes(o.value),
    )

    return (
      <Select
        type="multi"
        placeholder={field.placeholder}
        options={field.options}
        readOnly={isReadOnly}
        value={selectedValues}
        onChange={(options) => onChangeValue?.(options.map((o) => o.value))}
      />
    )
  } else if (field.type === ORDER_FIELD_TYPES.date) {
    return (
      <InputDate
        placeholder={field.placeholder}
        readOnly={isReadOnly}
        value={Array.isArray(value) ? '' : value}
        onChange={(value) => onChangeValue?.([value])}
      />
    )
  } else if (field.type === ORDER_FIELD_TYPES.time) {
    return (
      <InputPattern
        placeholder={field.placeholder}
        format="##:##"
        allowEmptyFormatting={false}
        readOnly={isReadOnly}
        value={Array.isArray(value) ? '' : value}
        onChange={(e) => onChangeValue?.([e.currentTarget.value])}
      />
    )
  } else if (field.type === ORDER_FIELD_TYPES.dateTime) {
    return (
      <InputPattern
        placeholder={field.placeholder}
        format="##.##.#### ##:##"
        allowEmptyFormatting={false}
        readOnly={isReadOnly}
        value={Array.isArray(value) ? '' : value}
        onChange={(e) => onChangeValue?.([e.currentTarget.value])}
      />
    )
  } else if (field.type === ORDER_FIELD_TYPES.phone) {
    return (
      <InputPhone
        placeholder={field.placeholder}
        readOnly={isReadOnly}
        value={Array.isArray(value) ? '' : value}
        onChange={(e) => onChangeValue?.([e.currentTarget.value])}
      />
    )
  } else if (field.type === ORDER_FIELD_TYPES.fileUpload) {
    return (
      <div>
        <Text s="sub-headline-bold">{field.label}</Text>
        <Text s="caption1" my="8" color="text-300">
          Прикрепите до 6 файлов. Размер одного файла не более 10 MB <br />
          Формат файла DOC (DOCX) / PDF / XLS (XLSX) / JPG / PNG / BMP
        </Text>
        <FilesUpload
          files={files}
          onChange={onChangeFiles}
          maxFilesCount={isReadOnly ? 0 : Math.max(6 - (value?.length || 0), 0)}
        >
          {orderId &&
            remoteFiles.map((file) => (
              <OrderFilePreview
                key={file.fileName}
                isReadOnly={isReadOnly}
                orderId={orderId}
                fieldId={field.id.toString()}
                file={file}
              />
            ))}
        </FilesUpload>
      </div>
    )
  } else if (field.type === ORDER_FIELD_TYPES.photoUpload) {
    return (
      <div>
        <Text s="sub-headline-bold">{field.label}</Text>
        <Text s="caption1" my="8" color="text-300">
          Прикрепите до 6 файлов. Размер одного файла не более 10 MB <br />
          Формат файла JPG / PNG / BMP
        </Text>
        <FilesUpload
          files={files}
          onChange={onChangeFiles}
          fileMimeTypes={IMAGE_MIME_TYPES}
          fileExtensions={IMAGE_FILE_EXTENSIONS}
          maxFilesCount={
            isReadOnly
              ? 0
              : Math.max(field.maxFilesNumber - (value?.length || 0), 0)
          }
        >
          {orderId &&
            remoteFiles.map((file) => (
              <OrderFilePreview
                key={file.fileName}
                isReadOnly={isReadOnly}
                orderId={orderId}
                fieldId={field.id.toString()}
                file={file}
              />
            ))}
        </FilesUpload>
      </div>
    )
  } else {
    field satisfies never
    return null
  }
}
