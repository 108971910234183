import { withStyles } from '@bruitt/classnames'

import { BackgroundColorProps, ColorProps } from '../../types/colors'

import s from './box.module.scss'

const sx = withStyles(s)

type Px =
  | '0'
  | '2'
  | '4'
  | '6'
  | '8'
  | '10'
  | '12'
  | '14'
  | '16'
  | '20'
  | '24'
  | '32'
  | '40'
  | '48'
  | '64'
type Display =
  | 'none'
  | 'block'
  | 'inline-block'
  | 'inline'
  | 'flex'
  | 'inline-flex'
  | 'grid'
  | 'inline-grid'
  | 'hidden'
type Rad =
  | '0'
  | '2'
  | '4'
  | '6'
  | '8'
  | '10'
  | '12'
  | '16'
  | '20'
  | '24'
  | 'half'
type GrowShrink = '0' | '1' | '2' | '3' | '4'
type WidthAndHeight =
  | 'auto'
  | 'full'
  | 'max-content'
  | 'min-content'
  | 'fit-content'
type GridColumnSpan = '1' | '2' | '3' | '4'
interface BoxModelProps {
  // margins
  m?: Px
  mx?: Px
  my?: Px
  mt?: Px
  mb?: Px
  ml?: Px
  mr?: Px
  // margins sm
  m_sm?: Px
  mx_sm?: Px
  my_sm?: Px
  mt_sm?: Px
  mb_sm?: Px
  ml_sm?: Px
  mr_sm?: Px
  // margins md
  m_md?: Px
  mx_md?: Px
  my_md?: Px
  mt_md?: Px
  mb_md?: Px
  ml_md?: Px
  mr_md?: Px
  // margins lg
  m_lg?: Px
  mx_lg?: Px
  my_lg?: Px
  mt_lg?: Px
  mb_lg?: Px
  ml_lg?: Px
  mr_lg?: Px
  // margins xl
  m_xl?: Px
  mx_xl?: Px
  my_xl?: Px
  mt_xl?: Px
  mb_xl?: Px
  ml_xl?: Px
  mr_xl?: Px
  // paddings
  p?: Px
  px?: Px
  py?: Px
  pt?: Px
  pb?: Px
  pl?: Px
  pr?: Px
  // paddings sm
  p_sm?: Px
  px_sm?: Px
  py_sm?: Px
  pt_sm?: Px
  pb_sm?: Px
  pl_sm?: Px
  pr_sm?: Px
  // paddings md
  p_md?: Px
  px_md?: Px
  py_md?: Px
  pt_md?: Px
  pb_md?: Px
  pl_md?: Px
  pr_md?: Px
  // paddings lg
  p_lg?: Px
  px_lg?: Px
  py_lg?: Px
  pt_lg?: Px
  pb_lg?: Px
  pl_lg?: Px
  pr_lg?: Px
  // paddings xl
  p_xl?: Px
  px_xl?: Px
  py_xl?: Px
  pt_xl?: Px
  pb_xl?: Px
  pl_xl?: Px
  pr_xl?: Px
  // Hidden (display: none)
  hidden?: boolean
  hidden_sm?: boolean
  hidden_md?: boolean
  hidden_lg?: boolean
  hidden_xl?: boolean
  // Display
  display?: Display
  display_sm?: Display
  display_md?: Display
  display_lg?: Display
  display_xl?: Display
  // Flex grow and shrink
  gr?: GrowShrink
  sh?: GrowShrink
  // Grid
  span?: GridColumnSpan
  span_sm?: GridColumnSpan
  span_md?: GridColumnSpan
  span_lg?: GridColumnSpan
  span_xl?: GridColumnSpan

  // Width
  width?: WidthAndHeight
  height?: WidthAndHeight
}

type DivElementProps = Omit<JSX.IntrinsicElements['div'], 'color' | 'dir'>

export interface BoxProps
  extends BoxModelProps,
    ColorProps,
    BackgroundColorProps,
    DivElementProps {
  rad?: Rad
  rad_sm?: Rad
  rad_md?: Rad
  rad_lg?: Rad
  rad_xl?: Rad
  as?: React.ElementType
  children?: React.ReactNode
}

export const Box = ({
  children,
  className,
  onClick,
  as: Tag = 'div',
  style,
  ...rest
}: BoxProps) => {
  return (
    <Tag onClick={onClick} className={sx(className, rest)} style={style}>
      {children}
    </Tag>
  )
}
