import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { DashboardFilterRequest, getFinanceDetails } from '../api'
import { DASHBOARD_FINANCE_DETAILS_QUERY_KEY } from '../constants'

export const useFinanceDetails = (filter: DashboardFilterRequest) => {
  const { data, ...rest } = useQuery({
    queryKey: [DASHBOARD_FINANCE_DETAILS_QUERY_KEY, filter],
    queryFn: () => getFinanceDetails(filter),
    placeholderData: keepPreviousData,
  })

  return { financeDetails: data, ...rest }
}
