import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { InputDate } from '../../../../shared/uikit/input-date'
import { Select } from '../../../../shared/uikit/select'
import { RentAreaListItem } from '../../../rent-area/types'
import { DASHBOARD_FILTER_DEFAULT_FORM_VALUES } from '../../constants'
import { DashboardFilterFormValues } from '../../types'

interface DashboardMobileFilterFormProps {
  values: DashboardFilterFormValues
  onChange: (values: DashboardFilterFormValues) => void
  projects: RentAreaListItem['project'][]
  rentAreas: RentAreaListItem[]
  onClose?: () => void
}

export const DashboardMobileFilterForm = ({
  values,
  onChange,
  projects,
  rentAreas,
  onClose,
}: DashboardMobileFilterFormProps) => {
  const handleChange = (newValues: DashboardFilterFormValues) => {
    onChange({ ...values, ...newValues })
  }

  return (
    <Flex dir="column" g="12" height="full" jc="space-between">
      <Flex dir="column" g="12">
        <Select
          type="multi"
          valueName="id"
          labelName="name"
          placeholder="Проект"
          options={projects}
          value={values.projects}
          onChange={(projects) => handleChange({ projects })}
        />
        <Select
          type="multi"
          valueName="rentAreaId"
          labelName="unit"
          placeholder="Помещение"
          options={rentAreas}
          value={values.rentAreas}
          onChange={(rentAreas) => handleChange({ rentAreas })}
        />
        <InputDate
          placeholder="Период, от"
          value={values.dateMin}
          onChange={(dateMin) => handleChange({ dateMin })}
        />
        <InputDate
          placeholder="Период, от"
          value={values.dateMax}
          onChange={(dateMax) => handleChange({ dateMax })}
        />
      </Flex>

      <Flex dir="column" g="8">
        <Button
          variant="tertiary"
          s="l"
          onClick={() => handleChange(DASHBOARD_FILTER_DEFAULT_FORM_VALUES)}
        >
          Сбросить фильтр
        </Button>
        <Button variant="primary" s="l" onClick={onClose}>
          Показать результаты
        </Button>
      </Flex>
    </Flex>
  )
}
