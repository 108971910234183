import { bffRequest } from '../../shared/api/bff-request'

import { EstimatedPriceDetails, FinanceDetail, FinanceSummary } from './types'

export interface DashboardFilterRequest {
  periodFilter?: {
    start?: string
    end?: string
  }
  projectIds?: string[]
  rentAreaIds?: string[]
}

export const getFinanceSummary = (data: DashboardFilterRequest) =>
  bffRequest.post<FinanceSummary>('/Dashboard/GetFinanceSummary', data)

export const getEstimatedPriceDetails = (data: DashboardFilterRequest) =>
  bffRequest.post<EstimatedPriceDetails>(
    '/Dashboard/GetEstimatedPriceDetails',
    data,
  )

export const getFinanceDetails = (data: DashboardFilterRequest) =>
  bffRequest.post<{ finances: FinanceDetail[] }>(
    '/Dashboard/GetFinanceDetails',
    data,
  )
