import { z } from 'zod'

import { RentAreaListItem } from '../rent-area/types'

export interface FinanceSummary {
  income: number
  expenses: number
  revenue: number
  percent: number
}

export interface FinanceDetail {
  startDate: string
  endDate: string
  income: number
  expenses: number
  revenue: number
}

export interface EstimatedPriceDetails {
  priceDiff: number
  percentDiff: number
  prices: {
    startDate: string
    date: string
    price: number
  }[]
}

export interface DashboardFilterFormValues {
  projects?: RentAreaListItem['project'][]
  rentAreas?: RentAreaListItem[]
  dateMin?: string
  dateMax?: string
}

export const dashboardFilterSearchSchema = z.object({
  projects: z.array(z.string()).optional(),
  rentAreas: z.array(z.string()).optional(),
  dateMin: z.string().optional(),
  dateMax: z.string().optional(),
})

export type DashboardFilterSearch = z.infer<typeof dashboardFilterSearchSchema>
