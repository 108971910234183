import { Box } from '../../../../shared/uikit/box'
import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { Icon } from '../../../../shared/uikit/icon'
import { IconButton } from '../../../../shared/uikit/icon-button'
import { InputDate } from '../../../../shared/uikit/input-date'
import { Select } from '../../../../shared/uikit/select'
import { RentAreaListItem } from '../../../rent-area/types'
import { DASHBOARD_FILTER_DEFAULT_FORM_VALUES } from '../../constants'
import { DashboardFilterFormValues } from '../../types'

import s from './dashboard-desktop-filter-form.module.scss'

interface DashboardDesktopFilterFormProps {
  values: DashboardFilterFormValues
  onChange: (values: DashboardFilterFormValues) => void
  projects: RentAreaListItem['project'][]
  rentAreas: RentAreaListItem[]
}

export const DashboardDesktopFilterForm = ({
  values,
  onChange,
  projects,
  rentAreas,
}: DashboardDesktopFilterFormProps) => {
  const handleChange = (newValues: DashboardFilterFormValues) => {
    onChange({ ...values, ...newValues })
  }

  return (
    <Flex g="12">
      <Select
        s="m"
        type="multi"
        variant="white"
        valueName="id"
        labelName="name"
        isClearable
        placeholder="Проект"
        options={projects}
        value={values.projects}
        onChange={(projects) => handleChange({ projects })}
      />
      <Select
        s="m"
        type="multi"
        variant="white"
        valueName="rentAreaId"
        labelName="unit"
        isClearable
        placeholder="Помещение"
        options={rentAreas}
        value={values.rentAreas}
        onChange={(rentAreas) => handleChange({ rentAreas })}
      />
      <InputDate
        s="m"
        variant="white"
        value={values.dateMin}
        onChange={(dateMin) => handleChange({ dateMin })}
        placeholder="Период, от"
      />
      <InputDate
        s="m"
        variant="white"
        value={values.dateMax}
        onChange={(dateMax) => handleChange({ dateMax })}
        placeholder="Период, от"
      />
      <Box hidden_lg>
        <IconButton
          icon="close"
          variant="tertiary"
          s="m"
          onClick={() => handleChange(DASHBOARD_FILTER_DEFAULT_FORM_VALUES)}
        />
      </Box>
      <Box hidden display_lg="block" className={s.clear}>
        <Button
          s="m"
          pre={<Icon name="close" color="primary-500" />}
          variant="ghost"
          onClick={() => handleChange(DASHBOARD_FILTER_DEFAULT_FORM_VALUES)}
        >
          Сбросить фильтр
        </Button>
      </Box>
    </Flex>
  )
}
