import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getProfilePhoto } from '../api'
import { USER_PROFILE_PHOTO_QUERY_KEY } from '../constants'

export const useProfilePhoto = (fileName?: string | null) => {
  const { data, ...rest } = useQuery({
    queryKey: [USER_PROFILE_PHOTO_QUERY_KEY, fileName],
    queryFn: () =>
      getProfilePhoto({
        fileName: fileName!,
      }),
    enabled: Boolean(fileName),
  })

  useEffect(() => {
    return () => {
      if (data) URL.revokeObjectURL(data)
    }
  }, [data])

  return { photoUrl: data, ...rest }
}
