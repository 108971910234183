import { bffAuthRequest } from '../../shared/api/bff-auth-request'
import { bffRequest } from '../../shared/api/bff-request'

import { Profile, User } from './types'

interface SendOtpRequest {
  phone: string
}

export const sendOtp = (data: SendOtpRequest) =>
  bffAuthRequest.post('/users/SendOtpCode', data)

interface LoginRequest {
  phone: string
  code: string
}

interface LoginResponse {
  accessToken: string
}

export const login = (data: LoginRequest) =>
  bffAuthRequest.post<LoginResponse>('/users/LoginUserMobile', data)

export const getCurrentUser = () =>
  bffRequest.get<User>('/users/GetLoggedInUser/me')

export const getProfile = () => bffRequest.post<Profile>('/users/GetProfile')

interface GetProfilePhotoRequest {
  fileName: string
}

export const getProfilePhoto = ({ fileName }: GetProfilePhotoRequest) =>
  bffRequest
    .get<Blob>(`/users/GetFile?fileName=${fileName}`)
    .then((blob) => URL.createObjectURL(blob))
    .catch(() => '')
