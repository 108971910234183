import { DashboardFilterFormValues } from './types'

export const DASHBOARD_FINANCE_SUMMARY_QUERY_KEY = 'dashboard-finance-summary'
export const DASHBOARD_ESTIMATED_PRICE_DETAILS_QUERY_KEY =
  'dashboard-estimated-price-details'
export const DASHBOARD_FINANCE_DETAILS_QUERY_KEY = 'dashboard-finance-details'

export const DASHBOARD_FILTER_DEFAULT_FORM_VALUES: DashboardFilterFormValues = {
  rentAreas: [],
  projects: [],
  dateMin: '',
  dateMax: '',
}
